import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContext";
import { getSessions } from "../services/services/account-services";
import "./Style.css"


function History() {
  const [order, setOrder] = useState([]);
  const context = useContext(AppContext);

  useEffect(() => {
    const { setIsAuthenticated, setIsLoading } = context;

    const token = localStorage.getItem("AuthentificationToken");
    axios
      .get(
        `http://103.125.70.205:5555/api/v1/Session/GetSessions?SelectOrders=false`,

        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data.data, "getsession123");
        setOrder(resp.data.data);
        if (localStorage.getItem("AuthentificationToken")) {
          setIsAuthenticated(true);
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <section className="dashboard-landing-pnl container-fluid stickyFooter">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>History</h1>
                <div className="full-div table-contnr">
                  <table>
                    <caption>Session History</caption>
                    <thead>
                      <tr>
                        <td>SYMBOL</td>
                        <td>STATUS</td>
                        <td>CREATED AT</td>
                        <td>SOLD VOL</td>
                        <td>BOUGHT VOL</td>
                        <td>MAX AMOUNT</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.sort().map((ord, ind) => {
                        return (
                          <tr>
                            <td>{ord.sessionSymbol}</td>
                            <td>
                              {ord.sessionStatus == 0
                                ? "Running"
                                : ord.sessionStatus == 1
                                ? "Stopped"
                                : ord.sessionStatus == 2
                                ? "Cancelled"
                                : null}{" "}
                            </td>
                            <td>{ord.createdAt.split('T')[0]} {ord.createdAt.split('T')[1].split('.')[0]}</td>
                            <td>{ord.totalVolSold.toFixed(3)} USDT</td>
                            <td>{ord.totalVolBought.toFixed(3)} USDT</td>
                            <td>{ord.maxUsageAmount.toFixed(3)} USDT</td>
                            <Link to="/sessionIdHistory" state={{data: ord.sessionId}}  >
                            <td >View Orders</td>
                            </Link>
                         
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default History;
