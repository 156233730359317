import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useToasts } from "react-toast-notifications";
import { Eye, EyeOff } from "react-feather";
import "./Style.css"

import { login } from "../services/services/account-services";

function Login() {
  const context = useContext(AppContext);
  const { addToast } = useToasts();

  const [logEmail, setLogEmail] = useState();
  const [logPassword, setLogPassword] = useState();

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passowErrorMessage, setPasswordErrorMessage] = useState("");

  const [hiddenPassword, setHiddenPassword] = useState()


  const navigate = useNavigate();
  const handleChange = (e) => {
    var nameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
    var emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var passwordRegx =
      /^(?=.*\d)(?=.*[!@#.$%_^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    var countryRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
    // var addrs = new RegExp("^[a-zA-Z0-9\s,'-]*$")
    var addrs = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;

    const { name, value } = e.target;

    if (name === "email") {
      if (!value.match(emailReg)) {
        console.log("defaulter er");
        setEmailErrorMessage("Enter Valid Email");
        setLogEmail(value);
      } else {
        setLogEmail(value);
        setEmailErrorMessage("");
      }
    } else if (name === "password") {
      if (!value.match(passwordRegx)) {
        setPasswordErrorMessage("Password required");
        setLogPassword(value);
      } else {
        setLogPassword(value);
        setPasswordErrorMessage("");
      }
    }
  };

  const Login = () => {
    if (logEmail !== "" && logPassword !== "") {
      const { setIsAuthenticated,setIsLoading } = context;
      setIsLoading(true)
      let data = {
        userInfo: logEmail,
        password: logPassword,
        deviceToken: "ok",
        deviceModel: "ok",
        os: "ok",
        version: "ok",
        deviceType: 3,
      };
      // axios
      //   .post("http://69.60.115.140:4002/api/v1/Account/login", data, {})


      login(data)
        .then((res) => {
          localStorage.setItem("AuthentificationToken", res.data.data.accessToken);
          setIsAuthenticated(true);
          setIsLoading(false)
          navigate("/dashboard");
        })
        .catch((error) => {
          setIsLoading(false)
          addToast("email or password is incorrect", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        });

      //     login(data)
      //       .then((response) => {
      //         localStorage.setItem(
      //           "AuthentificationToken",
      //           response.data.data.accessToken
      //         );
      //         alert("ggg")
      // //         if(response){
      // //           localStorage.setItem(
      // //             "AuthentificationToken",
      // //             response.data.data.accessToken
      // //           );
      // //           setIsAuthenticated(true);
      // //           navigate("/dashboard");
      // //           alert("ifff")
      // //         }
      // //         else{
      // //           addToast("Login Falied check email or password",  { appearance: 'error', autoDismiss:true, autoDismissTimeout:2000 });
      // // alert("hoooooooooo")
      // //         }

      //       }).catch(()=>{

      //       })
    } else {
      addToast("Please fill all the fields correctly", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  };
  const toggleShow1 = () => {
    setHiddenPassword(!hiddenPassword);
  };
  return (
    <div className="row stickyFooter">
      <div className="container">
        {/* <div className="row"> */}

        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
          <div
              style={{
              marginTop: "300px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "30vw" }}>
              <input
                name="email"
                type="text"
                className="form-control "
                placeholder="Enter your email address"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <span className="text-danger">{emailErrorMessage}</span>

              <div style={{position:"relative"}}>


              <input
                name="password"
                type={hiddenPassword?"text":"password"}
                className="form-control "
                placeholder="Enter your Password"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <span className="text-danger">{passowErrorMessage}</span>

              <div
                  className="form-control-position"
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    zIndex: 5,
                    width:"auto",
                    display:"inline-block"
                  }}
                >
                  <span className="" onClick={toggleShow1}>
                    {hiddenPassword === true ? (
                      <EyeOff
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    ) : (
                      <Eye
                        style={{ cursor: "pointer", color: "white" }}
                        size={18}
                      />
                    )}
                  </span>
                </div>
                </div>

              <div className="full-div text-right">
                <Link to="/forgetPassword">
                  <li style={{ cursor: "pointer", color: "white" }}>
                    {/* <button
                      className="form-submit-btn"
                    //   onClick={() => SignInShow(false)}
                    > */}
                    Forgot Password
                    {/* </button> */}
                  </li>
                </Link>
              </div>
              <div className="full-div text-center">
                <button href="#" className="reg-btn blue full" onClick={Login}>
                  LOGIN
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Login;
