import { toast } from "react-toastify";
import { useToasts } from "react-toast-notifications";

// import axios from "./interceptores/custom-axios";
import axios from "../interceptores/custom-axios";
import networkEndpoints from "../network-endpoint";
// // /**
// //  * functional Variables
// //  */ https://harvestgains-api.azurewebsites.net/swagger/index.html
// const baseUrl = 'http://198.187.28.244:9191/api/';

// const baseUrl = "http://69.60.115.140:4002/api/";
const baseUrl = "http://103.125.70.205:5555/api/";

// export const makeBaseUrl = (version) => `${baseUrl}v${version}`;
// /**
//  * Account Apis
//  * @returns
//  */
// const { addToast } = useToasts();

export const login = (request) =>
 new Promise((resolve,reject)=>{
    axios()
    .post(baseUrl + networkEndpoints.login, request)
    .then((response) => {
        resolve(response)
  
    })
    .catch((error) => {
        reject(error)
       
    });

})


export const signUp = (request) =>
new Promise((resolve,reject) =>{
    axios()
    .post(baseUrl+networkEndpoints.signup, request)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})

export const resetPassword = (request) =>
new Promise((resolve,reject) =>{
    axios()
    .post(baseUrl+networkEndpoints.resetpassword, request)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})

export const getActiveSession = () =>
new Promise((resolve,reject) =>{
    axios()
    .get(baseUrl+networkEndpoints.getactivesession)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})

export const getSessions = (request) =>
new Promise((resolve,reject) =>{
    axios()
    .get(baseUrl+networkEndpoints.getsessions+`?SessionId=${request}&SelectOrders=true`)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})

export const stopSessions = () =>
new Promise((resolve,reject) =>{
    axios()
    .get(baseUrl+networkEndpoints.stopsessions)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})

export const cancelActiveSession = () =>
new Promise((resolve,reject) =>{
    axios()
    .get(baseUrl+networkEndpoints.cancelactivesession)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})


export const forgetPassword = (request) =>
new Promise((resolve,reject) =>{
    axios()
    .get(baseUrl+networkEndpoints.forgetpassword+request)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})

export const verifyEmail = (request) =>
new Promise((resolve,reject) =>{
    axios()
    .get(baseUrl+networkEndpoints.verifyemail+`${request}`)
    .then((response) => {
        resolve(response)
    })
    .catch((error)=>{
        reject(error)
    })

})


  





// let token = localStorage.getItem("token");
// const config = {
//   headers: { Authorization: `Bearer ${token}` },
// };

// export const addUser = async (request) =>
//   await axios()
//     .post(makeBaseUrl(1) + networkEndpoints.addUser, request)
//     .then((response) => {
//       if (!response.data.isError) {
//         return response.data;
//       } else {
//         toast.warn("Login failed");

//         return false;
//       }
//     })
//     .catch((error) => {
//       toast.error(`${error.response.data.message}`);
//     });
