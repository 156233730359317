import React, { useState, useEffect, useContext } from "react";
import dashboard from "../assets/img/dashboard.png";
import edit from "../assets/img/editprice.png";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import Loader from "react-js-loader";
import percent from "../assets/img/play.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  getActiveSession,
  getSessions,
  stopSessions,
  cancelActiveSession,
} from "../services/services/account-services";
import AppContext from "../context/AppContext";
import { TrendingDown } from "react-feather";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
// import { Tab, Tabs } from "react-bootstrap";

function Dashboard() {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const [data, setData] = useState();
  const [chkActiveSession, getChkActiveSession] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [order, setOrder] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [sessionStatus, setSessionStatus] = useState("");
  const [totalBalance, setTotalBalance] = useState()
  const [totalAvailBalance, setAvailTotalBalance] = useState()
  const [totalBalanceMarigin, setTotalBalanceMargin] = useState()
  const [BNBAsset, setBNBAsset] = useState()
  const [BUSDAsset, setBUSDAsset] = useState()
  const [USDTAsset, setUSDTAsset] = useState()
  const [trendHistory, setTrendHistory] = useState()
  const [loginModalShow, setloginModalShow] = useState(false)
  const [orderHistory, setOrderHistory] = useState([])
  const [APIKEY, setAPIKEY] = useState();
  const [SecretKey, setSecretKey] = useState()
  const [confirmModalShow, setConfirmModalShow] = useState()
  const [quantity, setQuantity] = useState()
  const [leverage, setLeverage] = useState()
  const [disablebot, setDisablebot] = useState(false)


  const unlearned = () => {
    axios.get('https://unlearned-be.ibt-learning.com/api/getallactiveorders').then((res) => {
      if (res.data.data == "No API key entered") {
      }
      else {
        setOrderHistory(res.data)
      }
      console.log("rrrrrrr order", res);
    }).catch((err) => {
      console.log("errrrrr", err);
    })
    axios
      .get(`https://unlearned-be.ibt-learning.com/api/getbalance`).then((res) => {
        setTotalBalance(res.data.totalWalletBalance)
        setAvailTotalBalance(res.data.availableBalance)
        setTotalBalanceMargin(res.data.totalBalanceinMargin)
        res.data.assets.map((type) => {
          if (type.asset == "BNB") {
            setBNBAsset(type.walletBalance)
          }
          else if (type.asset == "USDT") {
            setUSDTAsset(type.walletBalance)
          }
          else if (type.asset == "BUSD") {
            setBUSDAsset(type.walletBalance)
          }
        })
        console.log("result is", res)
      }).catch((err) => {
        console.log("result is", err)
      })

    axios.get('https://unlearned-be.ibt-learning.com/api/getuncertain').then((res) => {
      console.log("trend is", res)
      setTrendHistory(res.data)
    }).catch((err) => {
      console.log("err", err);
    })
  }


  const startBot = () => {
    axios.post(`https://unlearned-be.ibt-learning.com/api/startbot`).then((res) => {
      console.log("okokokok", res);
      alert("Bot start successfully")
      localStorage.setItem("botInput", true)
      setDisablebot(true)
      setConfirmModalShow(false)
      setloginModalShow(false)
      // window.location.reload()
    }).catch((err) => {
      console.log("nonoon", err);
    })
  }

  const stopBot = () => {



    axios.post(`https://unlearned-be.ibt-learning.com/api/stopbot`).then((res) => {
      alert("Bot stopped successfully")
      setDisablebot(false)
      localStorage.clear()
      axios.post(`https://unlearned-be.ibt-learning.com/api/deletekeys`).then(() => {
      }).catch((err) => {
        console.log("nonoon", err);
      })
    }).catch((err) => {
      console.log("nonoon", err);
    })
  }

  const getQuantity = () => {
    axios.get('https://unlearned-be.ibt-learning.com/api/getquantity').then((res) => {
      console.log("quantity is", res.data[0].qty)
      setQuantity(res.data[0].qty)

    }).catch((err) => {
      console.log("err", err);
    })
  }
  const getLeverage = () => {
    axios.get('https://unlearned-be.ibt-learning.com/api/getleverage').then((res) => {
      console.log("leverage is is", res)
      setLeverage(res.data[0].number)
    }).catch((err) => {
      console.log("err", err);
    })
  }

  const AddQuantity = () => {
    const body = {
      qty: quantity
    }
    axios.post(`https://unlearned-be.ibt-learning.com/api/quantity`, body).then((res) => {
      addToast(`Added successfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      console.log("okokokok", res)
    }).catch((err) => {
      console.log("nonoon", err);
    })
  }

  const AddLeverage = () => {
    const body = {
      number: parseFloat(leverage)
    }
    axios.post(`https://unlearned-be.ibt-learning.com/api/leverage`, body).then((res) => {
      addToast(`Added successfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      console.log("okokokok", res)
    }).catch((err) => {
      console.log("nonoon", err);
    })
  }

  useEffect(() => {
    getLeverage()
    getQuantity()
    unlearned()
    let interMat = setInterval(() => {
      unlearned()
    }, [5000]);

    return () => clearInterval(interMat);
  }, []);

  const GetActiveSession = () => {
    const { setIsAuthenticated, setIsLoading } = context;
    getActiveSession()
      .then((resp) => {
        getChkActiveSession(resp.data.data, "getActiveSession");
        if (localStorage.getItem("AuthentificationToken")) {
          setIsAuthenticated(true);
        }
        setActiveSession(resp.data.data);
        setSessionId(resp.data.data.sessionId);
        console.log("Status", resp.data.data.sessionStatus);
        setSessionStatus(resp.data.data.sessionStatus);
        let seId = resp.data.data.sessionId;

        getSessions(seId)
          .then((resp) => {
            console.log(resp.data.data[0].orders, "getsession123");
            setOrder(resp.data.data[0].orders);
          })
          .catch((error) => { });
      })
      .catch((error) => { });
  };
  const stopSession = () => {
    const { setIsAuthenticated, setIsLoading } = context;
    setIsLoading(true);
    stopSessions()
      .then((resp) => {
        setIsLoading(false);

        if (!resp.data.isError) {
          addToast(`${resp.data.message}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        } else {
          addToast(`${resp.data.message}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };


  const ApplyStart = () => {
    const body = {
      apiKey: APIKEY,
      secretKey: SecretKey
    }
    axios.post(`https://unlearned-be.ibt-learning.com/api/postkeys`, body).then((res) => {
      console.log("okokokok", res);
      setConfirmModalShow(true)
    }).catch((err) => {
      console.log("nonoon", err);
    })
  }

  const cancelSession = () => {

    const { setIsAuthenticated, setIsLoading } = context;
    setIsLoading(true);
    cancelActiveSession()
      .then((resp) => {
        setIsLoading(false);

        if (!resp.data.isError) {
          addToast(`${resp.data.message}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsLoading(false);

          addToast(`${resp.data.message}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((error) => { });
  };

  return (
    <>
      <Modal
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Confirm to start</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setConfirmModalShow(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="full-div text-center">
            <p style={{ color: "white" }}>Are you sure you want to proceed? make sure your API key has withdraw funds off</p>
          </div>
          <div className="full-div text-center">
            <a href="#" className="reg-btn blue full" onClick={() => {
              startBot()
            }}>
              Confirm
            </a>
            <a href="#" className="reg-btn ml-3 blue full" onClick={() => {
              setConfirmModalShow(false)
            }}>
              Cancel
            </a>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={loginModalShow}
        onHide={() => setloginModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Start</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setloginModalShow(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="password"
            className="form-control password"
            placeholder="APIKEY"
            onChange={(e) => {
              setAPIKEY(e.target.value)
            }}
          />
          <input
            type="password"
            className="form-control password"
            placeholder="SecretKey"
            onChange={(e) => {
              setSecretKey(e.target.value)
            }}
          />
          <div className="full-div text-center">
            <a href="#" className="reg-btn blue full" onClick={() => {
              ApplyStart()
            }}>
              Apply
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <section className="dashboard-landing-pnl container-fluid">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Manage your Bot Session</h1>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className="flex-div run-btns-pnl col-lg-12 col-md-12 col-sm-12">
                  <div>
                    <label style={{ color: "white" }}><b>Quantity:</b></label>
                    {
                      localStorage.getItem("botInput") ? <input
                        disabled={true}
                        type="text"
                        className="ml-3"
                        placeholder="Enter Quantity"
                        style={{ width: "30vw" }}
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value)
                        }}
                      /> : <input
                        disabled={disablebot}
                        type="text"
                        className="ml-3"
                        placeholder="Enter Quantity"
                        style={{ width: "30vw" }}
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value)
                        }}
                      />
                    }

                    <img style={{ cursor: "pointer" }} onClick={() => {
                      AddQuantity()
                    }} width="25px" className="ml-3" src={edit} />
                  </div>
                  <br />
                  <div>
                    <label style={{ color: "white" }}><b>LeverAge:</b></label>

                    {
                      localStorage.getItem("botInput") ? <input
                        disabled={true}
                        value={leverage}
                        style={{ width: "30vw" }}
                        onChange={(e) => {
                          setLeverage(e.target.value)
                        }}
                        type="text"
                        className="ml-3"
                        placeholder="Enter leverage"
                      /> : <input
                        disabled={disablebot}
                        value={leverage}
                        style={{ width: "30vw" }}
                        onChange={(e) => {
                          setLeverage(e.target.value)
                        }}
                        type="text"
                        className="ml-3"
                        placeholder="Enter leverage"
                      />
                    }



                    <img disabled={true} width="25px" style={{ cursor: "pointer" }} onClick={() => {
                      AddLeverage()
                    }} className="ml-3" src={edit} />
                  </div>
                </div>
                <div className="flex-div run-btns-pnl">
                  <ul className="dashboard-list">
                    <li>

                      <>
                        <a
                          href="#"
                          className="reg-btn blue full"
                          onClick={() => {
                            // startBot();
                            setloginModalShow(true)
                          }}
                        >
                          Start
                        </a>
                        <img
                          style={{ cursor: "pointer" }}
                          height={100}
                          src={percent}
                          onClick={() => {
                            startBot();
                          }}
                        />
                      </>

                    </li>
                    {chkActiveSession === null ? null : (
                      <>
                        {" "}
                        <li>
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={stopBot}
                          >
                            Stop
                          </a>
                          <button>
                            <i className="fa fa-stop"></i>
                          </button>
                        </li>
                        <li className="volume-list">
                          <div>
                            <p className="reg-btn blue">
                              Total Balance{" "}
                              <span>
                                {parseFloat(totalBalance).toFixed(2)}
                              </span>
                            </p>
                            <p className="reg-btn blue">
                              Available Balance{" "}
                              <span>
                                {parseFloat(totalAvailBalance).toFixed(2)}
                              </span>
                            </p>
                            <p className="reg-btn blue">
                              Balance Margin{" "}
                              <span>
                                {parseFloat(totalBalanceMarigin).toFixed(2)}
                              </span>
                            </p>

                            {/* <p className="reg-btn blue full">
                              Total volume sold{" "}
                              <span>{chkActiveSession?.totalVolSold} USDT</span>
                            </p> */}
                          </div>
                        </li>
                        <li className="volume-list">
                          <div>
                            <p style={{ width: "170px" }} className="reg-btn blue p-3">
                              {/* Total Balance{" "} */}
                              <span>
                                {parseFloat(BNBAsset).toFixed(2)} BNB
                              </span>
                            </p>
                            <p style={{ width: "170px" }} className="reg-btn blue p-3">
                              {/* Available Balance{" "} */}
                              <span>
                                {parseFloat(USDTAsset).toFixed(2)} USDT
                              </span>
                            </p>
                            <p style={{ width: "170px" }} className="reg-btn blue p-3">
                              {/* Balance Margin{" "} */}
                              <span>
                                {parseFloat(BUSDAsset).toFixed(2)} BUSD
                              </span>
                            </p>

                            {/* <p className="reg-btn blue full">
                              Total volume sold{" "}
                              <span>{chkActiveSession?.totalVolSold} USDT</span>
                            </p> */}
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                {/* <p>
                  So lets begin the journey of trust together and set new
                  milestones of trust.
                </p> */}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="full-div table-contnr">
                  <table>
                    <caption>Trends</caption>
                    <thead>
                      <tr>
                        <td>CandleIndex</td>
                        <td>HighPrice</td>
                        <td>LowPrice</td>
                        <td>Time</td>

                      </tr>
                    </thead>
                    <tbody>

                      {
                        trendHistory?.slice(0, 10)?.map((trend) => {
                          return (
                            <tr>
                              <td>{trend?.candleIndex}</td>
                              <td>{trend?.highPrice}</td>
                              <td>{trend?.lowPrice}</td>
                              <td>{moment.unix(trend?.timeStamp / 1000).format("DD MMM YYYY hh:mm a")}</td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
              {chkActiveSession == null ? (
                <></>
              ) : (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="full-div table-contnr">
                      <table>
                        <caption>Orders</caption>
                        <thead>
                          <tr>
                            <td>Id</td>
                            <td>Symbol</td>
                            <td>Price</td>
                            <td>OrigQty</td>
                            <td>Side</td>
                          </tr>
                        </thead>
                        <tbody>
                          {orderHistory?.slice(0, 10)?.map((order) => {
                            return (
                              <tr>
                                <td>
                                  {order?.orderId}
                                </td>
                                <td>
                                  {order?.symbol}
                                </td>
                                <td>
                                  {order?.price}
                                </td>
                                <td>
                                  {order?.origQty}
                                </td>
                                <td>
                                  {order?.side}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Dashboard;
