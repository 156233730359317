import React from 'react';
import cntrimg from '../assets/img/bg.png';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
function Home() {
    return (
        <>
            {/* Landing Panel */}
            <div id="landing-header"
            // style={{ backgroundImage: `url(${bgimg})` }}
            >
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <h1>Manage your Assets
                                        with us to get Securer
                                        Assets with loyalty.</h1>
                                    <p>So lets begin the journey of trust together
                                        and set new milestones of trust.</p>
                                    <a href="/dashboard" className='reg-btn blue'>Start Bot</a>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <img src={cntrimg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Landing Panel */}
        </>
    );
}
export default Home;