import React, { useContext, useEffect, useState } from "react";
import bgimg from "../assets/img/bg.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import AppContext from "../context/AppContext";
import "./Style.css";

function Bot() {
  const context = useContext(AppContext);

  useEffect(() => {
    const { setIsAuthenticated } = context;

    const interMat = setInterval(() => {
      if (localStorage.getItem("AuthentificationToken")) {
        setIsAuthenticated(true);
      }
    }, [50]);
    return () => clearInterval(interMat);
  }, []);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [radioFree, setRadioFree] = useState("");
  const [iJZApiKey, setIJZApiKey] = useState("");
  const [iJZSecretApiKey, setIJZSecretApiKey] = useState("");
  const [binanceApiKey, setBinanceApiKey] = useState("");
  const [binanceSecretKey, setBinanceSecretKey] = useState("");
  const [baseOrderAmount, setBaseOrderAmount] = useState("");
  const [baseOrderError, setBaseOrderError] = useState("");
  const [baseError, setBaseError] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const [getPair, setGetPairDAta] = useState([]);
  const [marketId, setMarketId] = useState("select");
  const [planDesc, setPlanDesc] = useState({});
  const [isEligible, setIsEligible] = useState(false);
  const [maxUseAmount, setMaxUseAmount] = useState(0);
  const [SOVol, setSOVol] = useState(0);

  const [keyDisabled, setKeyDisabled] = useState(false);

  useEffect(() => {
    //CalculateMaxUsageAndFirstSO(3, 1.05, 17); //124.185
    GetCurrentUser();

    GetPairData();
  }, []);

  const handleBaseOrder = (val) => {
    if (val === "") {
      const { MaxUsageAmount, firstSoVol } = CalculateMaxUsageAndFirstSO(
        planDesc.maxSo,
        planDesc.soscaling,
        0
      );
      setMaxUseAmount(MaxUsageAmount);
      setSOVol(firstSoVol);
    } else {
      const { MaxUsageAmount, firstSoVol } = CalculateMaxUsageAndFirstSO(
        planDesc.maxSo,
        planDesc.soscaling,
        parseInt(val)
      );
      setMaxUseAmount(MaxUsageAmount);
      setSOVol(firstSoVol);
    }
    setBaseOrderAmount(val);

    if (val < 15) {
      setBaseOrderError("Base Order Amount must be larger than 15 USDT");
      setBaseError(true);
    } else {
      setBaseOrderError("");
      setBaseError(false);
    }
  };

  const StartSession = () => {
    if (!baseError && marketId != "select" && !apiError &&!secretError ) {
      let data = {
        planId: planDesc.planId,
        marketId: marketId,
        baseOrderAmount: parseFloat(baseOrderAmount),
        binanceApiKey: binanceApiKey,
        binanceSecretKey: binanceSecretKey,
        iinjazApiKey: iJZApiKey,
        iinjazSecertKey: iJZSecretApiKey,
      };
      let token = localStorage.getItem("AuthentificationToken");
      axios
        .post(`http://103.125.70.205:5555/api/v1/Session/StartSession`, data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          if (!resp.data.isError) {
            navigate("/dashboard");
            addToast("Session Started Successfully", {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
          } else {
            addToast(`${resp.data.message}`, {
              appearance: "error",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
          }
        })
        .catch((error) => {});
    } else {
      addToast("Please fill all the fields correctly", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }
  };

  const GetPairData = () => {
    let token = localStorage.getItem("AuthentificationToken");
    axios
      .get(
        `http://103.125.70.205:5555/api/v1/Exchange/GetPairsData
          `,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        setGetPairDAta(resp.data.data);
      })
      .catch((error) => {});
  };

  const GetPlans = () => {
    if (radioFree == "" || radioFree == null) {
      addToast("Please Select Plan", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }
    let token = localStorage.getItem("AuthentificationToken");
    axios
      .get(
        ` http://103.125.70.205:5555/api/v1/Plans/GetPlans?ApiKey=${iJZApiKey}&SecretKey=${iJZSecretApiKey}
          `,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        if (resp.data.isError === false) {
          const plan = resp.data.data;

          const selectedPlan = plan.filter((item) => {
            if (item.planName === radioFree) {
              return item;
            }
          });
          if (selectedPlan[0].isEligible === true) {
            console.log("Plan ", selectedPlan[0]);
            addToast("Plan selected", {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
            setIsEligible(true);
            setKeyDisabled(true);
            setPlanDesc(selectedPlan[0]);
          }
        } else if (resp.data.isError === true) {
          addToast("Not eligible for this plan", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }

        console.log(resp.data.isError, "bottttttttttttttt");
      })
      .catch((error) => {});
  };
  const [ApiKey1, setApiKey1] = useState(false);
  const GetCurrentUser = () => {
    let token = localStorage.getItem("AuthentificationToken");
    axios
      .get(
        `http://103.125.70.205:5555/api/v1/Account/GetCurrentUser
          `,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data.data.binanceApikey, "GetCurrrentUser");
        setBinanceApiKey(resp.data.data.binanceApikey);

        if (resp.data.data.binanceApikey) {
          setApiKey1(true);
        }
      })
      .catch((error) => {});
  };

  const RadioChange = (name) => {
    setRadioFree(name);
    setIJZApiKey("");
    setIJZSecretApiKey("");
  };

  function CalculateMaxUsageAndFirstSO(MaxSo, SoScaling, BaseOrderAmount = 0) {
    //// calculation
    let firstSo = BaseOrderAmount * 2;
    let maxUsageAmount = BaseOrderAmount + firstSo;
    let lastSo = firstSo;
    for (let i = 2; i <= MaxSo; i++) {
      lastSo = lastSo * SoScaling;
      maxUsageAmount += lastSo;
    }
    // debugger
    console.log(maxUsageAmount, firstSo);
    return { MaxUsageAmount: maxUsageAmount, firstSoVol: firstSo };
  }
  const [apiError, setApiError] = useState("");
  const [secretError, setSecretError] = useState("");
  const ValidationError = (e) => {
    const { name, value } = e.target;

    if ((name == "APIkey")) {  
      if (value == "" || value == null) {
        setApiError("Enter Api Key");
        setBinanceApiKey("");
      } else {
        setBinanceApiKey(value);
        setApiError("");
      }
    } else  if ((name == "Secretkey")) {  
      if (value == "" || value == null) {
        setSecretError("Enter Secret Key");
        setBinanceSecretKey();
      } else {
        setBinanceApiKey(value);
        setSecretError("");
      }
    }
  };

  return (
    <>
      <section className="trading-bot container-fluid stickyFooter">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="iinjaz-bot-form full-div">
                  <div className="iinjaz-bot-head full-div">
                    <h1>Unlearned</h1>
                  </div>
                  <div className="iinjaz-bot-body full-div">
                    <form>
                      {/* <div className="full-div">
                        <label>Plans</label>
                        <div className="full-div marg-bot">
                          <div className="form-check form-check-inline">
                            <input
                              disabled={isEligible ? true : false}
                              className="form-check-input"
                              onClick={() => {
                                RadioChange("Free");
                              }}
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option2"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              free
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              disabled={isEligible ? true : false}
                              className="form-check-input"
                              onClick={() => {
                                RadioChange("Silver");
                              }}
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Silver
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              disabled={isEligible ? true : false}
                              className="form-check-input"
                              onClick={() => {
                                RadioChange("Gold");
                              }}
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="option3"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              Gold
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              disabled={isEligible ? true : false}
                              className="form-check-input"
                              onClick={() => {
                                RadioChange("Platinum");
                              }}
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio3"
                              value="option4"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio3"
                            >
                              Platinum
                            </label>
                          </div>
                        </div>
                      </div> */}
                      {radioFree === "Free" ? null : (
                        <>
                          {" "}
                          <div className="full-div">
                            <label>Binance API Key</label>
                            <input
                              disabled={keyDisabled ? true : false}
                              type="text"
                              className="form-control "
                              placeholder=""
                              onChange={(e) => {
                                setIJZApiKey(e.target.value);
                              }}
                            />
                          </div>
                          <div className="full-div">
                            <label>
                              Binance Secret Key
                            </label>
                            <input
                              disabled={keyDisabled ? true : false}
                              type="text"
                              className="form-control"
                              placeholder=""
                              onChange={(e) => {
                                setIJZSecretApiKey(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      )}
                      {isEligible ? null : (
                        <div className="full-div text-center">
                          <a
                            href="#"
                            className="reg-btn blue full"
                            onClick={() => GetPlans()}
                          >
                            Apply 
                          </a>
                        </div>
                      )}
                      {isEligible ? (
                        <>
                          {ApiKey1 ? (
                            <>
                              <div className="full-div">
                                <label>Enter Binance API Key</label>
                                <input
                                  disabled={ApiKey1 ? true : false}
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                  onChange={(e) => {
                                    setBinanceApiKey(e.target.value);
                                  }}
                                  value={binanceApiKey}
                                />
                              </div>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setApiKey1(false);
                                  setBinanceApiKey("kkkkkkkkkkk");
                                }}
                              >
                                Click to enter Binance API and Secret Keys
                              </a>
                            </>
                          ) : (
                            <>
                              <div className="full-div">
                                <label>Enter Binance API Key</label>
                                <input
                                  disabled={ApiKey1 ? true : false}
                                  name="APIkey"
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                  onChange={(e) => {
                                    // setBinanceApiKey(e.target.value);
                                    ValidationError(e);
                                  }}
                                  // value={binanceApiKey}
                                />
                                <span style={{ color: "red" }}>{apiError}</span>
                              </div>

                              <div className="full-div">
                                <label>Enter Binance Secret Key</label>
                                <input
                                  name="Secretkey"
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  onChange={(e) => {
                                    // setBinanceSecretKey(e.target.value);
                                    ValidationError(e)
                                  }}
                                />
                                                                <span style={{ color: "red" }}>{secretError}</span>

                              </div>
                            </>
                          )}

                          <div className="full-div">
                            <label>Base Order Volume (USDT)</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              onChange={(e) => {
                                handleBaseOrder(e.target.value);
                              }}
                            />
                            {baseError ? (
                              <span style={{ color: "red" }}>
                                {baseOrderError}
                              </span>
                            ) : null}
                          </div>
                          <div className="custom-vals-label row">
                            <div className="w-50">
                              <label>Safety Order Volume</label>
                            </div>
                            <div className="w-50">
                              <label>Max. Volume Usage</label>
                            </div>
                          </div>
                          <div className="custom-vals row">
                            <div className="w-50">
                              <span style={{ color: "#000", fontWeight: 600 }}>
                                {SOVol}
                              </span>
                            </div>
                            <div className="w-50">
                              <span style={{ color: "#000", fontWeight: 600 }}>
                                {maxUseAmount.toFixed(5)}
                              </span>
                            </div>
                          </div>

                          <div className="full-div">
                            <label>Select Trade Token</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setMarketId(e.target.value);
                              }}
                            >
                              <option value="select">Select Token</option>
                              {getPair?.map((item, index) => {
                                return (
                                  <option key={index} value={item.marketId}>
                                    {item.baseAsset}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="full-div text-center">
                            <a
                              href="#"
                              className="reg-btn blue full"
                              onClick={StartSession}
                            >
                              START INJAZ BOT
                            </a>
                          </div>
                        </>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Bot;
